import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import CsodBlockContent from '../../molecules/BlockContent/BlockContent';
import Card from '../../atoms/Card/Card';
import { Heading2, Heading4 } from '../../atoms/Headings/Headings';
import Link from '../../atoms/Link/Link';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  breakpoints,
  colors,
  fontWeights,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

const OfficeRegions = ({ regions }) => {
  const sectionCss = css`
    padding: ${uc('100px 20px')};
    background-image: url('/gray-curves.png');
    background-repeat: no-repeat;
    background-size: ${pageDimensions.largeDesktopWidth};

    p,
    a {
      line-height: ${lineHeights.title};
    }

    a[href^='tel:'] {
      color: ${colors.mediumGray};
    }

    a[href^="http://"],
    a[href^="https://"],
    a[href^="mailto:"] {
      color: ${colors.primary};
      font-weight: ${fontWeights.medium};
    }

    @media (${breakpoints.largeTablet}) {
      padding-top: ${uc('75px')};
      padding-bottom: ${uc('20px')};
    }

    @media (${breakpoints.mobile}) {
      padding-top: ${uc('45px')};
    }
  `;

  const headingCss = css`
    margin-top: ${uc('30px')};
    color: ${colors.darkGray.three};
    text-align: center;
  `;

  const locationContainerCss = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `;

  const columnCss = css`
    flex: 0 0 30%;

    @media (${breakpoints.largeTablet}) {
      flex: 0 0 48%;
    }

    @media (${breakpoints.mobile}) {
      flex: 0 0 100%;
    }
  `;

  const locationCardCss = css`
    min-height: ${uc('335px')};
    margin-bottom: ${uc('45px')};
    padding: ${uc('50px')};

    div h4 {
      margin-bottom: 0;
      color: ${colors.darkGray.three};
    }
  `;

  const mapCss = css`
    margin: ${uc('-23px 0 20px')};
  `;

  // assigns window value for builds with no browser
  const initialWidth = typeof window === 'undefined' ? 1390 : window.innerWidth;

  const [windowWidth, setWindowWidth] = useState(initialWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const makeColumns = locations => {
    let columns;

    // Set number of columns based on page width
    if (windowWidth < 480) {
      // one column on mobile - no need to continue to the sort
      return new Array(locations);
    }
    if (windowWidth < 1080) {
      // two columns on large tablet and tablet
      columns = [[], []];
    } else {
      // three columns on desktop
      columns = [[], [], []];
    }

    let column = 0;
    /* eslint-disable */
    // sort tiles into columns
    for (let i = 0; i < locations.length; i++) {
      columns[column].push(locations[i]);
      column === columns.length - 1 ? (column = 0) : column++;
    }
    /* eslint-enable */
    return columns;
  };

  const renderLocationCards = ({ locations }) => {
    const locationColumns = makeColumns(locations);

    return locationColumns.map(locationColumn => (
      <div
        css={columnCss}
        key={
          locationColumn[0]
            ? `location-column-${locationColumn[0]._key}`
            : 'empty'
        }
      >
        {locationColumn.map(location => (
          <Card key={`location-${location._key}`} css={locationCardCss}>
            <Heading4>{location.heading}</Heading4>
            {location.addresses.map(address => (
              <div key={`address-${address._id}`}>
                <CsodBlockContent blocks={address.streetAddress} />
                {address.mapLink &&
                  address.mapLink.link &&
                  address.mapLink.link.length > 0 && (
                    <p css={mapCss}>
                      <Link to={address.mapLink}>{address.mapLink.text}</Link>
                    </p>
                  )}
                {address.contactLinks && address.contactLinks.length > 0 && (
                  <CsodBlockContent blocks={address.contactLinks} />
                )}
              </div>
            ))}
            {location.localSiteLink &&
              location.localSiteLink.link.length > 0 && (
                <Link to={location.localSiteLink}>
                  {location.localSiteLink.text}
                </Link>
              )}
          </Card>
        ))}
      </div>
    ));
  };

  const renderedRegions = regions.map(region => (
    <div key={`region-${region._key}`}>
      <Heading2 css={headingCss}>{region.heading}</Heading2>
      <div css={locationContainerCss}>{renderLocationCards(region)}</div>
    </div>
  ));

  return (
    <div css={sectionCss}>
      <PageWrapper>{renderedRegions}</PageWrapper>
    </div>
  );
};

OfficeRegions.propTypes = {
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string.isRequired,
          addresses: PropTypes.instanceOf(Array).isRequired,
          localSiteLink: PropTypes.shape({}),
        })
      ).isRequired,
    })
  ),
};

OfficeRegions.defaultProps = {
  regions: null,
};

export default OfficeRegions;

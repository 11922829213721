import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';

import CsodBlockContent from '../BlockContent/BlockContent';
import AnimatedButton from '../../atoms/Buttons/AnimatedButton/AnimatedButton';
import Card from '../../atoms/Card/Card';
import { Heading3 } from '../../atoms/Headings/Headings';
import Image from '../../atoms/Image/Image';
import Section from '../Section/Section';

import locationsIcon from './assets/locations-icon.png';
import watergardenImage from './assets/watergarden.jpg';

import {
  breakpoints,
  colors,
  fontWeights,
  lineHeights,
  pageDimensions,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';

const HeadquartersCard = ({
  animatedButton,
  contactList,
  headingText,
  streetAddress,
}) => {
  const sectionCss = css`
    margin-top: ${uc('-250px')};

    @media (${breakpoints.desktop}) {
      /* need to specify here to override media query in Section.jsx */
      margin-top: ${uc('-250px')};
    }

    @media (${breakpoints.mobile}) {
      margin-top: ${uc('-268px')};
    }
  `;

  const cardCss = css`
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    width: ${pageDimensions.desktopWidth};
    height: ${uc('455px')};
    margin: 0 auto;
    overflow: hidden;

    @media (${breakpoints.desktop}) {
      width: calc(100% - ${uc('40px')});
    }

    @media (${breakpoints.smallDesktopMax}) {
      margin-top: ${uc('100px')};
    }

    @media (${breakpoints.mobile}) {
      height: auto;
    }
  `;

  const backgroundCss = css`
    position: absolute;
    left: 0;
    width: 37.5%;
    height: 100%;
    background: url(${watergardenImage});
    background-size: cover;
    clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      clip-path: none;
    }
  `;

  const cardContentCss = css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    margin-left: 37%;
    padding: ${uc('0 100px 0 72px')};

    @media (${breakpoints.largeTablet}) {
      width: 100%;
      margin-left: 0;
      padding: ${uc('48px')};
      background-color: rgba(255, 255, 255, 0.9);
    }
  `;

  const locationsIconCss = css`
    position: absolute;
    top: ${uc('64px')};
    left: ${uc('-144px')};
    width: ${uc('140px')};
    height: ${uc('140px')};
    border-radius: 50%;
    box-shadow: ${shadows.large};
  `;

  const textContainerCss = css`
    position: relative;
    display: flex;
    justify-content: space-around;

    h3,
    p,
    a {
      line-height: ${lineHeights.title};
    }

    a[href^='tel:'] {
      color: ${colors.mediumGray};
    }

    a[href^="http://"],
    a[href^="https://"],
    a[href^="mailto:"] {
      color: ${colors.primary};
      font-weight: ${fontWeights.medium};
    }

    .left,
    .right {
      max-width: 50%;
    }

    .left h3 {
      color: ${colors.primary};
    }

    .right h4 {
      margin-bottom: 0;
      color: ${colors.darkGray.three};
    }

    @media (${breakpoints.tablet}) {
      p,
      a[href^='tel:'] {
        color: ${colors.darkGray.three};
      }
    }

    @media (${breakpoints.mobile}) {
      display: block;

      .left,
      .right {
        max-width: 100%;
      }

      .right {
        margin-top: ${uc('20px')};
      }

      button,
      a {
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
  `;

  return (
    <Section css={sectionCss}>
      <Card css={cardCss} className="carousel-card">
        <div css={backgroundCss} />
        <div css={cardContentCss}>
          <Image css={locationsIconCss} src={locationsIcon} />
          <div css={textContainerCss}>
            <div className="left">
              <Heading3>{headingText}</Heading3>
              <CsodBlockContent blocks={streetAddress} />
              <AnimatedButton {...animatedButton}>
                {animatedButton && animatedButton.link.text}
              </AnimatedButton>
            </div>
            <div className="right">
              <CsodBlockContent blocks={contactList} />
            </div>
          </div>
        </div>
      </Card>
    </Section>
  );
};

HeadquartersCard.propTypes = {
  animatedButton: PropTypes.shape({
    link: PropTypes.shape({ text: PropTypes.string }),
  }),
  contactList: PropTypes.instanceOf(Array),
  headingText: PropTypes.string,
  streetAddress: PropTypes.instanceOf(Array),
};

HeadquartersCard.defaultProps = {
  animatedButton: false,
  contactList: [],
  headingText: '',
  streetAddress: [],
};

export default HeadquartersCard;

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import HeadquartersCard from '../../molecules/HeadquartersCard/HeadquartersCard';
import OfficeRegions from '../../organisms/OfficeRegions/OfficeRegions';
import SlantBanner from '../../organisms/SlantBanner/SlantBanner';
import wgHeaderImage from './assets/wg_header.jpg';
import {
  breakpoints,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import useLocalize from '../../../hooks/useLocalize';

const OfficeLocationsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.officeLocationsPage === null) {
    return null;
  }
  const data = useLocalize(rawData, locale);
  const officeLocationsPageProps = {
    id: data.officeLocationsPage._id,
    metadata: { ...data.officeLocationsPage._rawRoute, href },
    slantBanner: data.officeLocationsPage._rawOfficeLocationSlantBanner,
    regions: data.officeLocationsPage._rawRegions,
  };

  // puts heading inside a textBlock object since SlantBanner expects textBlock
  officeLocationsPageProps.slantBanner.textBlock = {
    headingText: officeLocationsPageProps.slantBanner.heading,
  };

  // adds backgroundImage from codebase (not entered in Sanity)
  officeLocationsPageProps.slantBanner.backgroundImage = wgHeaderImage;

  const officeLocationSlantBannerCss = css`
    div div {
      max-width: 100%;
    }

    .page-wrapper .text-block {
      h1 {
        margin-bottom: ${uc('50px')};
        font-weight: ${fontWeights.light};
        font-size: ${fontSizes.sixty};

        @media (${breakpoints.tablet}) {
          font-size: ${fontSizes.forty};
        }

        @media (${breakpoints.mobile}) {
          font-size: ${fontSizes.thirtyEight};
        }
      }

      @media (${breakpoints.largeTablet}) {
        background: none;
      }
    }
  `;

  return (
    <Layout
      menuColor="white"
      locale={locale}
      sanityId={officeLocationsPageProps.id}
      {...officeLocationsPageProps.metadata}
    >
      <SlantBanner
        templateSpecificStyles={officeLocationSlantBannerCss}
        {...officeLocationsPageProps.slantBanner}
      >
        <HeadquartersCard
          {...officeLocationsPageProps.slantBanner.headquartersCard}
        />
      </SlantBanner>
      <OfficeRegions regions={officeLocationsPageProps.regions} />
    </Layout>
  );
};

OfficeLocationsPage.propTypes = {
  data: PropTypes.shape({ officeLocationsPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: {
    locale: PropTypes.string,
  },
};

OfficeLocationsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};
export default OfficeLocationsPage;

export const query = graphql`
  query($slug: String) {
    officeLocationsPage: sanityOfficeLocationsPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawOfficeLocationSlantBanner(resolveReferences: { maxDepth: 100 })
      _rawRegions(resolveReferences: { maxDepth: 100 })
    }
  }
`;
